var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('SectionHeader',{attrs:{"title":"Abastecimentos","actions":[
        {
          icon: 'mdi-refresh',
          eventName: 'click:refresh',
          label: 'Atualizar',
        },
      ]},on:{"click:refresh":_vm.load}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[(_vm.items.length)?_c('v-card',{attrs:{"outlined":""}},[_c('v-list',{staticClass:"py-0 bordered"},_vm._l((_vm.items),function(item){return _c('ListItem',_vm._b({key:item.id},'ListItem',item,false))}),1)],1):_vm._e()],1),_c('PaginationBar',{attrs:{"pagination":_vm.pagination},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }