<template>
  <v-list-item :to="url">
    <v-list-item-avatar>
      <v-icon>mdi-gas-cylinder</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <span class="grey--text">#{{ id }}</span>

        {{ condominium.name }}</v-list-item-title
      >
      <v-list-item-subtitle>
        <KChip x-small orange class="mr-1">
          Abastecido em
          {{ supplied_at | dateBR }}
        </KChip>

        <KChip x-small cyan class="mr-1"
          >{{ price_by_m3 | currency }} /m³</KChip
        >
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action> {{ supply_price | currency }} </v-list-item-action>
  </v-list-item>
</template>

<script>
import KChip from "@/components/KChip";
export default {
  name: "supply-list-item",

  components: {
    KChip
  },

  props: {
    id: Number,
    price_by_m3: [String, Number],
    supply_price: [String, Number],
    supplied_at: String,
    condominium_id: Number,
    billet_payed_at: String,
    billet_expires_at: String,
    condominium: { type: Object, default: () => ({}) }
  },

  computed: {
    // Só exibe o condomínio na url do item se ele também existir na url da página
    url() {
      if (this.$route.params.condominium_id) {
        return `/collect/condominiums/${this.condominium_id}/supplies/view/${this.id}`;
      } else {
        return `/collect/supplies/view/${this.id}`;
      }
    }
  }
};
</script>

<style>
</style>
