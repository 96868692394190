<template>
  <!--  -->
  <v-row no-gutters>
    <v-col cols="12" class="py-1">
      <SectionHeader
        title="Abastecimentos"
        :actions="[
          {
            icon: 'mdi-refresh',
            eventName: 'click:refresh',
            label: 'Atualizar',
          },
        ]"
        @click:refresh="load"
      />
    </v-col>

    <v-col cols="12" class="py-1">
      <v-card outlined v-if="items.length">
        <v-list class="py-0 bordered">
          <ListItem v-for="item in items" :key="item.id" v-bind="item" />
        </v-list>
      </v-card>
    </v-col>

    <!-- <v-col cols="12" class="py-1">
      <pre>{{ payload }}</pre>
    </v-col> -->

    <!-- Barra de paginação -->
    <PaginationBar :pagination="pagination" v-model="page" />
  </v-row>
</template>

<script>
import PaginationBar from "@/components/Pagination/Bar";
import SectionHeader from "@/components/SectionHeader";

import ListItem from "./ListItem";

export default {
  components: {
    ListItem,

    PaginationBar,
    SectionHeader
  },

  props: {
    // Filtra pelo condomínio
    condominium_id: [String, Number],
    // status: pago ou pendent
    onlyPendents: Boolean
  },

  data() {
    return {
      page: 1,
      pagination: {},
      items: []
    };
  },

  watch: {
    page() {
      this.load();
    }
  },

  computed: {
    payload() {
      const payload = { page: this.page };

      if (this.condominium_id) payload.condominium_id = this.condominium_id;
      if (this.onlyPendents) payload.only_pendents = true;

      return payload;
    }
  },

  methods: {
    async load() {
      try {
        const { data: result } = await this.$api.get("/supplies", this.payload);
        const { data: items, ...pagination } = result.data;
        // console.log(items, pagination);

        this.items = items;
        this.pagination = pagination;
        this.page = pagination.page;
        this.$emit("load", result.data);
      } catch (error) {
        this.$message.serverError(error);
      }
    }
  },

  mounted() {
    this.load();
  }
};
</script>

<style>
</style>
