<template>
  <!-- Formulário -->
  <KForm ref="form">
    <!-- Dados do Fornecedor -->
    <v-row v-if="showCondominium" class="my-0" style="width: 100%">
      <v-col cols="12" class="py-1">
        <SectionHeader
          title="Dados do Condomínio"
          :actions="[
            {
              to: `/collect/condominiums/add`,
              target: '_blank',
              icon: 'mdi-plus',
              label: 'Novo condomínio',
            },
          ]"
        />
      </v-col>

      <!-- Condomínio -->
      <v-col cols="12" class="py-1">
        <v-autocomplete
          outlined
          dense
          label="Condomínio"
          item-value="id"
          item-text="name"
          return-object
          :items="condominiums"
          :error-messages="errors.condominium_id"
          v-model="condominiumData"
          @change="onSelectCondominium"
        />
      </v-col>
    </v-row>

    <!-- Dados do Fornecedor -->
    <v-row class="my-0" style="width: 100%">
      <v-col cols="12" class="py-1">
        <SectionHeader
          title="Dados do Fornecedor"
          :actions="[
            {
              to: `/people/add`,
              target: '_blank',
              icon: 'mdi-plus',
              label: 'Cadastrar nova pessoa',
            },
          ]"
        />
      </v-col>

      <!-- Fornecedor -->
      <v-col cols="12" class="py-1">
        <PersonInfoSelect
          outlined
          dense
          label="Fornecedor"
          :person="form.supplier"
          :error-messages="errors.supplier_id"
          :search-params="{ showRoles: '' }"
          v-model="form.supplier_id"
          @clear="form.supplier_id = {}"
        />
      </v-col>
    </v-row>

    <!-- Dados do abastecimento -->
    <v-row class="my-2" style="width: 100%">
      <v-col cols="12" class="py-0">
        <SectionHeader title="Dados do Abastecimento" class="mt-2 mb-4" />
      </v-col>

      <!-- Valor Total do Abastecimento -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          label="Valor Total"
          dense
          outlined
          prefix="R$"
          :precision="2"
          v-model="form.supply_price"
          :error-messages="errors.supply_price"
          @keyup="calcFinalPrice"
        />
      </v-col>

      <!-- Quantidade abastecida -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          :label="`Quantidade em ${calculatedParams.supply_unit}`"
          dense
          outlined
          :suffix="calculatedParams.supply_unit"
          :precision="3"
          v-model="form.supply_qty"
          :error-messages="errors.supply_qty"
          @keyup="calcFinalPrice"
        />
      </v-col>

      <!-- Valor por unidade de abastecimento -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          :label="`Custo / ${calculatedParams.supply_unit}`"
          disabled
          dense
          outlined
          prefix="R$"
          :precision="2"
          v-model="calculatedParams.supply_cost_by_unit"
        />
      </v-col>

      <!-- Coeficiente de conversão -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          label="Coeficiente de Conversão"
          dense
          outlined
          :precision="2"
          disabled
          v-model="calculatedParams.conversion_coefficient"
        />
      </v-col>

      <!-- Custo/m³ -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          :label="`Custo / ${calculatedParams.save_unit}`"
          dense
          outlined
          prefix="R$"
          :precision="2"
          disabled
          v-model="calculatedParams.cost_by_unit"
        />
      </v-col>

      <!-- Lucro definido em contrato -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          label="Lucro definido em contrato"
          dense
          outlined
          suffix="%"
          :precision="2"
          disabled
          v-model="calculatedParams.profit_percentage"
        />
      </v-col>

      <!-- Valor Final Calculado -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          :label="`Valor Final / ${calculatedParams.save_unit} (Calculado)`"
          dense
          outlined
          prefix="R$"
          :precision="2"
          disabled
          v-model="calculatedParams.price_by_m3"
        />
      </v-col>

      <!-- Valor final / m³ -->
      <v-col cols="12" md="3" class="py-0">
        <KInputMoney
          type="number"
          :label="`Valor Final / ${calculatedParams.save_unit}`"
          dense
          outlined
          prefix="R$"
          :precision="2"
          :error-messages="errors.price_by_m3"
          v-model="form.price_by_m3"
        />
      </v-col>

      <v-col cols="12" md="3" class="py-0">
        <KInputDate
          label="Data do Abastecimento"
          dense
          outlined
          :rules="[]"
          v-model="form.supplied_at"
          :error-messages="errors.supplied_at"
        />
      </v-col>
    </v-row>

    <!-- Mostra só no cadastro -->
    <template v-if="!isEditing">
      <v-row class="my-0">
        <v-col cols="12" class="py-1">
          <SectionHeader title="Informações do Boleto" />
        </v-col>
        <v-col cols="12" class="py-1 mb-4">
          <KAlert title="Informações" info>
            Será criada uma despesa com status pendente no módulo financeiro com
            o valor do abastecimento e a data de vencimento informada. Caso seja
            selecionada uma data de pagamento, a despesa será criada com o
            status pago.
          </KAlert>
        </v-col>

        <!-- Data de Vencimento do Boleto -->
        <v-col cols="12" md="3" class="py-0">
          <KInputDate
            label="Data de Vencimento"
            dense
            outlined
            :rules="[]"
            v-model="form.billet_expires_at"
            :error-messages="errors.billet_expires_at"
          />
        </v-col>

        <!-- Data de Pagamento do Boleto -->
        <v-col cols="12" md="3" class="py-0">
          <KInputDate
            label="Data de Pagamento"
            dense
            outlined
            :rules="[]"
            v-model="form.billet_payed_at"
            :error-messages="errors.billet_payed_at"
          />
        </v-col>
      </v-row>
    </template>

    <!-- Botão -->
    <v-row
      class="ma-0 mb-8"
      style="width: 100%"
      align="center"
      justify="center"
    >
      <v-btn color="accent" elevation="0" rounded @click="onSaveClick"
        >Salvar</v-btn
      >
    </v-row>

    <!-- Mostra só nas edições -->
    <template v-if="isEditing">
      <v-row class="my-0 mb-8">
        <v-col cols="12" class="py-1">
          <SectionHeader title="Ações" />
        </v-col>

        <v-col cols="12" class="py-1">
          <KAlert title="Atenção" warning>
            Este abastecimento possui uma movimentação financeira vinculada. Em
            caso de alterações, a movimentação financeira poderá ficar
            divergente, sendo necessário alterá-la também.<br />
            Clique em "Cobranças Relacionadas" para visualizar todas as
            movimentações que possuem vínculo com este abastecimento.
          </KAlert>
        </v-col>

        <v-col cols="12" class="py-1">
          <v-card outlined rounded="lg">
            <ExplicatedAction
              v-once
              title="Cobranças Relacionadas"
              description="Exibir movimentações financeiras relacionadas a este abastecimento"
              color="primary"
              event-name="click:view-transactions"
              @click:view-transactions="onViewTransactionsClick"
            />
          </v-card>
        </v-col>
      </v-row>
    </template>
  </KForm>
</template>

<script>
//
import { empty } from "klawtil";

//
import KForm from "@/components/KForm";
import KInputDate from "@/components/KInput/Date";
import KInputMoney from "@/components/KInput/Money";
import SectionHeader from "@/components/SectionHeader";
import KAlert from "@/components/KAlert";
import PersonInfoSelect from "@/components/PersonInfoSelect";

//
import ExplicatedAction from "@/components/ExplicatedAction";

export default {
  components: {
    KForm,
    KAlert,
    KInputMoney,
    KInputDate,
    SectionHeader,
    PersonInfoSelect,
    ExplicatedAction
  },

  props: {
    // Dados iniciais recebidos da api. Usado
    // quando é uma edição
    initialData: Object,
    // Erros recebidos da API
    errors: Object,
    // Objeto contendo os dados do produto que está sendo abastecido
    // product: Object,
    // Deve mostrar a opção de selecionar um condomínio? se for true,
    // um select com os condomínios será exibido para o usuário selecionar
    // qual o condomínio que está recebendo o abastecimento.
    showCondominium: Boolean,
    // Dados do condomínio
    condominium: Object
  },

  data() {
    return {
      form: {},
      condominiumData: {}
    };
  },

  watch: {
    initialData(value) {
      if (!empty(value)) {
        this.form = value;
      }
    }
  },

  computed: {
    /**
     * Fornece uma base de cálculo e de exibição para que os campos sejam corretamente
     * exibidos
     */
    calculatedParams() {
      let product = null;

      // if (this.condominiumData && this.condominiumData.product)
      //   product = this.condominiumData.product;
      // else
      if (this.product && this.product.supply_unit_id) product = this.product;

      // Dados do produto
      if (product) {
        // console.log(product);
        const { supply_unit = {}, conversion_coefficient } = product;

        const supply_cost_by_unit = (
          this.form.supply_price / this.form.supply_qty || 0
        ).toFixed(2);

        const cost_by_unit = (
          supply_cost_by_unit * conversion_coefficient || 0
        ).toFixed(2);

        // const { profit_percentage } = this.condominium;

        // Verifica de onde a informação de condomínio está vindo.
        // Se é do select ou via props
        const { profit_percentage = 0 } = this.calculatedCondominium;

        const price_by_m3 = (
          cost_by_unit *
          (1 + profit_percentage / 100)
        ).toFixed(2);

        return {
          // Unidade de suprimento
          supply_unit: supply_unit.short_name,
          // m³, que é a unidade utilizada no sistema
          save_unit: "m³",
          // Custo por unidade de suprimento
          supply_cost_by_unit,
          // Coeficiente de conversão
          conversion_coefficient,
          // Custo por m³, que é a unidade usada no sistema
          cost_by_unit,
          // Lucro aplicado neste condomínio
          profit_percentage,
          // Valor total do m³ com lugro incluído
          price_by_m3
        };
      }

      return {
        supply_unit: "m³",
        save_unit: "m³"
      };
    },

    products() {
      return this.$store.getters["products/all"];
    },
    product() {
      return (
        this.products.find(
          prod => prod.id == this.calculatedCondominium.product_id
        ) || {}
      );
    },

    /**
     *
     */
    payload() {
      const condominium = this.calculatedCondominium;

      const payload = {
        id: this.form.id,
        condominium_id: (condominium && condominium.id) || null,
        supplier_id: this.form.supplier_id,
        supply_price: this.form.supply_price,
        supply_qty: this.form.supply_qty,
        price_by_m3: this.form.price_by_m3,
        supplied_at: this.form.supplied_at,
        billet_expires_at: this.form.billet_expires_at,
        billet_payed_at: this.form.billet_payed_at
      };

      return payload;
    },

    /**
     * Verifica de onde a informação de condomínio está vindo.
     * Se é do select ou via props
     */
    calculatedCondominium() {
      let condominium = null;
      // Se tiver recebido condomínio por props
      if (this.condominium && this.condominium.id)
        condominium = this.condominium;

      // Se tiver recebido condomínio por meio do select
      if (this.condominiumData && this.condominiumData.id)
        condominium = this.condominiumData;

      return condominium || {};
    },

    /**
     * Lista de condomínios
     */
    condominiums() {
      return this.$store.getters["condominiums/all"];
    },

    /**
     * É edição?
     */
    isEditing() {
      return this.initialData && this.initialData.id;
    }
  },

  methods: {
    /**
     * Ao clicar
     */
    onSaveClick() {
      this.$emit("click:save", this.payload);
    },

    /**
     *
     */
    onViewTransactionsClick() {
      this.$router.push(
        "/financials/search?module=supply&module_id=" + this.initialData.id
      );
    },

    /**
     * Recalcula o valor final / m³ após a alteração da quantidade ou valor total
     */
    calcFinalPrice() {
      // Atualiza o valor de forma reativa
      this.$set(this.form, "price_by_m3", this.calculatedParams.price_by_m3);
    },

    /**
     * Ao selecionar um condomínio
     */
    onSelectCondominium(condominium) {
      // console.log("selecionou um condominio", condominium);
      this.$set(this.form, "condominium_id", condominium.id);
      this.calcFinalPrice();
    }
  },

  mounted() {
    this.$store.dispatch("condominiums/all");
  }
};
</script>

<style>
</style>
