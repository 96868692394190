var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KPage',[_c('CondominiumHeader',{attrs:{"condominium":_vm.condominiumData}},[_c('KChip',{attrs:{"orange":""}},[_vm._v(_vm._s(_vm.product.name))]),_c('KChip',{attrs:{"cyan":""}},[_vm._v(" Unidade de Abastecimento: "+_vm._s(_vm.product.supply_unit && _vm.product.supply_unit.short_name)+" ")]),_c('KChip',{attrs:{"cyan":""}},[_vm._v("Coeficiente de Conversão: "+_vm._s(_vm.product.conversion_coefficient))])],1),_c('FormSupply',{attrs:{"initial-data":_vm.form,"errors":_vm.errors,"show-condominium":false,"product":_vm.product,"condominium":_vm.condominiumData},on:{"click:save":_vm.save}}),(_vm.isEditing)?[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('SectionHeader',{attrs:{"title":"Documentos e Comprovantes","subtitle":"Arquivos relacionados com este abastecimento","actions":[
            {
              to: '/grouping',
              icon: 'mdi-plus',
              label: 'Cadastrar novo grupo',
            },
            {
              icon: 'mdi-refresh',
              eventName: 'click:refresh',
              label: 'Atualizar a lista de grupos',
            },
          ]},on:{"click:refresh":_vm.handleRefreshGroupClick}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('GroupsList',{ref:"groups-list",attrs:{"reference_module":"supply","reference_module_id":_vm.$route.params.supply_id}})],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }