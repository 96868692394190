<template>
  <KPage>
    <CondominiumHeader>
      <KChip cyan>
        {{ total | quantity("abastecimentos", "abastecimento") }}
      </KChip>
    </CondominiumHeader>

    <!--  -->
    <SuppliesList :condominium_id="condominium_id" @load="onLoadSupplies" />

    <!-- Separa o rodapé do botão de cadastrar -->
    <v-row style="height: 38px"></v-row>

    <!-- Botão FAB -->
    <FAB @click="add"></FAB>
  </KPage>
</template>

<script>
import FAB from "@/components/FAB";
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import CondominiumHeader from "@/views/Collect/condominiums/CondominiumHeader";

import SuppliesList from "./SuppliesList";

export default {
  name: "supplies-index",

  components: {
    FAB,
    KPage,
    KChip,
    CondominiumHeader,

    SuppliesList
  },

  data() {
    return {
      total: null
    };
  },
  computed: {
    condominium_id() {
      return this.$route.params.condominium_id;
    }
  },

  //
  methods: {
    add() {
      this.$router.push(
        `/collect/condominiums/${this.condominium_id}/supplies/add`
      );
    },

    onLoadSupplies(result) {
      this.total = result.total;
    }
  }
};
</script>

