<template>
  <KPage>
    <!-- Formulário com os dados -->
    <FormSupply :errors="errors" :show-condominium="true" @click:save="save" />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import FormSupply from "@/views/Collect/supplies/FormSupply";

export default {
  name: "page-add",

  components: {
    //
    KPage,
    //
    FormSupply
  },

  data() {
    return {
      errors: {},
      loading: false
    };
  },

  computed: {},

  methods: {
    /**
     *
     *
     */
    async save(payload) {
      try {
        this.$loading.show();
        this.errors = {};

        // console.log("payload recebido dentro do save da página", payload);

        const result = await this.$store.dispatch("supplies/save", {
          payload,
          editing: false
        });

        this.$loading.hide();
        this.$message.success("Registro salvo com sucesso");
        if (!this.isEditing) {
          this.$router.push(`/collect/supplies/view/${result.id}`);
        }
      } catch (error) {
        console.log(error);
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
      }
    }
  },

  async mounted() {
    this.$store.dispatch("products/all");
  }
};
</script>

<style>
</style>
