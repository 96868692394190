<template>
  <KPage>
    <!-- Card com o nome do condomínio -->
    <CondominiumHeader :condominium="condominiumData">
      <KChip orange>{{ product.name }}</KChip>

      <KChip cyan>
        Unidade de Abastecimento:
        {{ product.supply_unit && product.supply_unit.short_name }}
      </KChip>

      <KChip cyan
        >Coeficiente de Conversão: {{ product.conversion_coefficient }}</KChip
      >
    </CondominiumHeader>

    <!-- Formulário com os dados -->
    <FormSupply
      :initial-data="form"
      :errors="errors"
      :show-condominium="false"
      :product="product"
      :condominium="condominiumData"
      @click:save="save"
    />

    <!-- 
      Edição
    -->
    <template v-if="isEditing">
      <v-row>
        <v-col cols="12" class="py-1">
          <!-- Grupos -->
          <SectionHeader
            title="Documentos e Comprovantes"
            subtitle="Arquivos relacionados com este abastecimento"
            :actions="[
              {
                to: '/grouping',
                icon: 'mdi-plus',
                label: 'Cadastrar novo grupo',
              },
              {
                icon: 'mdi-refresh',
                eventName: 'click:refresh',
                label: 'Atualizar a lista de grupos',
              },
            ]"
            @click:refresh="handleRefreshGroupClick"
          />
        </v-col>

        <v-col cols="12" class="py-1">
          <GroupsList
            reference_module="supply"
            :reference_module_id="$route.params.supply_id"
            ref="groups-list"
          />
        </v-col>
      </v-row>
    </template>

    <!-- {{ payload }} -->
  </KPage>
</template>

<script>
import KForm from "@/components/KForm";
import KPage from "@/components/KPage";
import KChip from "@/components/KChip";
import SectionHeader from "@/components/SectionHeader";
import StatsLinkItem from "@/components/Stats/LinkItem";
import PersonInfoSelect from "@/components/PersonInfoSelect";
import KInputDate from "@/components/KInput/Date";
import KInputMoney from "@/components/KInput/Money";

//
import GroupsList from "@/views/Grouping/Children/List";
import CondominiumHeader from "@/views/Collect/condominiums/CondominiumHeader";
import FormSupply from "@/views/Collect/supplies/FormSupply";

//

export default {
  name: "page-save",

  components: {
    //
    KForm,
    KPage,
    KChip,
    KInputDate,
    KInputMoney,
    SectionHeader,
    StatsLinkItem,
    PersonInfoSelect,
    //
    GroupsList,
    FormSupply,
    CondominiumHeader
  },

  data() {
    return {
      form: {},
      errors: {},
      loading: false,
      condominium: {}
    };
  },

  computed: {
    isEditing() {
      return !!this.$route.params.supply_id;
    },
    products() {
      return this.$store.getters["products/all"];
    },
    product() {
      return (
        this.products.find(
          prod => prod.id == this.condominiumData.product_id
        ) || {}
      );
    },
    condominiumData() {
      if (this.form && this.form.condominium && this.form.condominium.name) {
        return this.form.condominium;
      } else if (this.condominium && this.condominium.name) {
        return this.condominium;
      }

      return {};
    }
  },

  methods: {
    /**
     *
     *
     */
    async save(payload) {
      try {
        this.$loading.show();
        this.errors = {};

        const result = await this.$store.dispatch("supplies/save", {
          payload,
          editing: this.isEditing
        });

        this.$loading.hide();
        this.$message.success("Registro salvo com sucesso");
        if (!this.isEditing) {
          this.$router.push(
            `/collect/condominiums/${result.condominium_id}/supplies/view/${result.id}`
          );
        }
      } catch (error) {
        console.log(error);
        this.$loading.hide();
        this.errors = this.$message.serverError(error);
      }
    },

    /**
     *
     */
    async loadCondominium() {
      try {
        if (this.$route.params.condominium_id) {
          const { data } = await this.$api.get(
            `/condominiums/${this.$route.params.condominium_id}`
          );

          this.condominium = data.data;
        }
      } catch (error) {
        this.$message.serverError(error);
      }
    },

    /**
     *
     *
     */
    async load() {
      this.$loading.show();
      this.loading = true;

      const { data } = await this.$api.get(
        `/supplies/${this.$route.params.supply_id}`
      );

      this.form = data.data;

      this.$app.title(`Abastecimento #${data.data.id}`);

      this.$loading.hide();
      this.loading = false;
    },

    /**
     * Atualiza o grupo
     */
    handleRefreshGroupClick() {
      this.$refs["groups-list"].loadItems();
    }
  },

  async mounted() {
    if (this.isEditing) {
      await this.load();
    }

    this.loadCondominium();
    this.$store.dispatch("products/all");
  }
};
</script>

<style>
</style>
