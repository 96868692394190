<template>
  <KPage>
    <!-- Abastecimentos -->
    <v-row class="mx-0 my-3">
      <v-col cols="12">
        <SectionHeader title="Estatísticas" />
      </v-col>
      <v-col class="pa-0 mb-4" v-for="item in stats" :key="item.title">
        <CardInfo v-bind="item" />
      </v-col>
    </v-row>

    <!--  -->
    <SuppliesList @load="onLoadSupplies" :only-pendents="only_pendents" />

    <!-- Separa o rodapé do botão de cadastrar -->
    <v-row style="height: 38px"></v-row>

    <SpeedDial
      :actions="speedDialActions"
      @click:add_supply="onAddSupplyClick"
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import SpeedDial from "@/components/SpeedDial.vue";
import CardInfo from "@/components/CardInfo";

import SuppliesList from "./SuppliesList";

export default {
  name: "supplies-index",

  components: {
    KPage,
    CardInfo,
    SpeedDial,
    SectionHeader,
    SuppliesList
  },

  data() {
    return {
      total: null,

      speedDialActions: [
        {
          eventName: "click:add_supply",
          label: "Novo Abastecimento",
          icon: "mdi-gas-cylinder",
          color: "indigo"
        }
      ]
    };
  },

  computed: {
    condominium_id() {
      return this.$route.params.condominium_id;
    },
    only_pendents() {
      return this.$route.query.only_pendents ? true : false;
    },

    stats() {
      const stats = this.$store.getters["collect_dashboard/all"];

      return [
        {
          label: "Abastecimentos",
          value: stats.supplies || "0",
          icon: "mdi-gas-cylinder"
        },
        {
          label: "Boletos Pendentes",
          icon: "mdi-alert-outline",
          to: "/financials/search?status_id=2&module=supply"
        },
        {
          label: "Novo abastecimento",
          icon: "mdi-plus",
          to: "/collect/supplies/add"
        }
      ];
    }
  },

  //
  methods: {
    onLoadSupplies(result) {
      this.total = result.total;
    },
    onAddSupplyClick() {
      this.$router.push("/collect/supplies/add");
    }
  },

  mounted() {
    this.$store.dispatch("collect_dashboard/refresh");
  }
};
</script>

